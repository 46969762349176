@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?y7qcav');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?y7qcav#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?y7qcav') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?y7qcav') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?y7qcav##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-send-opt-in {
  &:before {
    content: $icon-send-opt-in; 
  }
}
.icon-file-upload {
  &:before {
    content: $icon-file-upload; 
  }
}
.icon-ai {
  &:before {
    content: $icon-ai; 
  }
}
.icon-send-text {
  &:before {
    content: $icon-send-text; 
  }
}
.icon-send-poll {
  &:before {
    content: $icon-send-poll; 
  }
}
.icon-paperclip {
  &:before {
    content: $icon-paperclip; 
  }
}
.icon-template-action {
  &:before {
    content: $icon-template-action; 
  }
}
.icon-clickers {
  &:before {
    content: $icon-clickers; 
  }
}
.icon-template-copy {
  &:before {
    content: $icon-template-copy; 
  }
}
.icon-c-file-check {
  &:before {
    content: $icon-c-file-check; 
  }
}
.icon-c-number-lookup {
  &:before {
    content: $icon-c-number-lookup; 
  }
}
.icon-c-clone {
  &:before {
    content: $icon-c-clone; 
  }
}
.icon-c-phone-book {
  &:before {
    content: $icon-c-phone-book;     
    color: #5e7182;
  }
}
.icon-c-text {
  &:before {
    content: $icon-c-text;     
    color: #5e7182;
  }
}
.icon-clone-new {
  &:before {
    content: $icon-clone-new; 
  }
}
.icon-clone-failed {
  &:before {
    content: $icon-clone-failed; 
  }
}
.icon-campaigns {
  &:before {
    content: $icon-campaigns; 
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard; 
  }
}
.icon-import {
  &:before {
    content: $icon-import; 
  }
}
.icon-ip-proxies {
  &:before {
    content: $icon-ip-proxies; 
  }
}
.icon-knowledge-base {
  &:before {
    content: $icon-knowledge-base; 
  }
}
.icon-link-book {
  &:before {
    content: $icon-link-book; 
  }
}
.icon-mobile {
  &:before {
    content: $icon-mobile; 
  }
}
.icon-phone-book {
  &:before {
    content: $icon-phone-book; 
  }
}
.icon-prices {
  &:before {
    content: $icon-prices; 
  }
}
.icon-routes {
  &:before {
    content: $icon-routes; 
  }
}
.icon-short-domain {
  &:before {
    content: $icon-short-domain; 
  }
}
.icon-shortlink-proxy {
  &:before {
    content: $icon-shortlink-proxy; 
  }
}
.icon-sms-test {
  &:before {
    content: $icon-sms-test; 
  }
}
.icon-statistics {
  &:before {
    content: $icon-statistics; 
  }
}
.icon-stoplist {
  &:before {
    content: $icon-stoplist; 
  }
}
.icon-transactions {
  &:before {
    content: $icon-transactions; 
  }
}
.icon-users {
  &:before {
    content: $icon-users; 
  }
}
.icon-way-messaging {
  &:before {
    content: $icon-way-messaging; 
  }
}
.icon-default {
  &:before {
    content: $icon-default; 
  }
}
.icon-infobip {
  &:before {
    content: $icon-infobip; 
  }
}
.icon-infobip-sign {
  &:before {
    content: $icon-infobip-sign; 
  }
}
.icon-twilio {
  &:before {
    content: $icon-twilio; 
  }
}
.icon-twilio-sign {
  &:before {
    content: $icon-twilio-sign; 
  }
}
.icon-account {
  &:before {
    content: $icon-account; 
  }
}
.icon-actions {
  &:before {
    content: $icon-actions; 
  }
}
.icon-add {
  &:before {
    content: $icon-add; 
  }
}
.icon-alert {
  &:before {
    content: $icon-alert; 
  }
}
.icon-archive {
  &:before {
    content: $icon-archive; 
  }
}
.icon-archived {
  &:before {
    content: $icon-archived; 
  }
}
.icon-back {
  &:before {
    content: $icon-back; 
  }
}
.icon-basket {
  &:before {
    content: $icon-basket; 
  }
}
.icon-blog {
  &:before {
    content: $icon-blog; 
  }
}
.icon-chevron {
  &:before {
    content: $icon-chevron; 
  }
}
.icon-clear_input {
  &:before {
    content: $icon-clear_input; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-copy {
  &:before {
    content: $icon-copy; 
  }
}
.icon-custom {
  &:before {
    content: $icon-custom; 
  }
}
.icon-delete {
  &:before {
    content: $icon-delete; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-exit {
  &:before {
    content: $icon-exit; 
  }
}
.icon-export {
  &:before {
    content: $icon-export; 
  }
}
.icon-faq {
  &:before {
    content: $icon-faq; 
  }
}
.icon-file {
  &:before {
    content: $icon-file; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-finished {
  &:before {
    content: $icon-finished; 
  }
}
.icon-guide {
  &:before {
    content: $icon-guide; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-integration {
  &:before {
    content: $icon-integration; 
  }
}
.icon-log {
  &:before {
    content: $icon-log; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-notification {
  &:before {
    content: $icon-notification; 
  }
}
.icon-not-show {
  &:before {
    content: $icon-not-show; 
  }
}
.icon-number_check {
  &:before {
    content: $icon-number_check; 
  }
}
.icon-pause {
  &:before {
    content: $icon-pause; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-reload {
  &:before {
    content: $icon-reload; 
  }
}
.icon-report {
  &:before {
    content: $icon-report; 
  }
}
.icon-save {
  &:before {
    content: $icon-save; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-select {
  &:before {
    content: $icon-select; 
  }
}
.icon-settings {
  &:before {
    content: $icon-settings; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-show {
  &:before {
    content: $icon-show; 
  }
}
.icon-stop {
  &:before {
    content: $icon-stop; 
  }
}
.icon-stop-list {
  &:before {
    content: $icon-stop-list; 
  }
}
.icon-upm {
  &:before {
    content: $icon-upm; 
  }
}
.icon-upm-sign {
  &:before {
    content: $icon-upm-sign; 
  }
}
.icon-custom-route {
  &:before {
    content: $icon-custom-route; 
  }
}
.icon-twillio-sign {
  &:before {
    content: $icon-twillio-sign; 
  }
}
.icon-colored-upm-sign-1 {
  &:before {
    content: $icon-colored-upm-sign-1; 
  }
}
.icon-colored-upm-sign-2 {
  &:before {
    content: $icon-colored-upm-sign-2; 
  }
}
.icon-colored-upm-sign-3 {
  &:before {
    content: $icon-colored-upm-sign-3; 
  }
}
.icon-incoming-message {
  &:before {
    content: $icon-incoming-message; 
  }
}
.icon-image {
  &:before {
    content: $icon-image; 
  }
}
.icon-folder {
  &:before {
    content: $icon-folder; 
  }
}
.icon-waiting {
  &:before {
    content: $icon-waiting; 
  }
}
.icon-checkmark-stroke {
  &:before {
    content: $icon-checkmark-stroke; 
  }
}
.icon-need-attention {
  &:before {
    content: $icon-need-attention; 
  }
}
.icon-sent {
  &:before {
    content: $icon-sent; 
  }
}
.icon-alert-filled-circle {
  &:before {
    content: $icon-alert-filled-circle; 
  }
}
.icon-warning {
  &:before {
    content: $icon-warning; 
  }
}
.icon-taken-over {
  &:before {
    content: $icon-taken-over; 
  }
}
.icon-abandoned {
  &:before {
    content: $icon-abandoned; 
  }
}
.icon-in-progress {
  &:before {
    content: $icon-in-progress; 
  }
}
.icon-thesaurus {
  &:before {
    content: $icon-thesaurus; 
  }
}
.icon-conversation-templates {
  &:before {
    content: $icon-conversation-templates; 
  }
}

