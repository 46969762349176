@use "src/vars";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.row {
  display: flex;
}

.cell {
  flex: 1;
  border-right: 1px solid vars.$main-border-color;
  border-bottom: 1px solid vars.$main-border-color;
  transition: all 200ms ease;
  padding: 0;
  text-align: center;
}

.cellActive {
  background-color: vars.$primary-color;
  composes: theme-scheduler--cell-active from global;
}

.header {
  transition: all 200ms ease;
  color: vars.$gray-mid-color;
  background-color: vars.$layout-light;
}

.headerActive {
  color: vars.$text-color;
}

.disabled {
  .cellActive {
    background-color: vars.$gray-light-color;
  }
  .headerActive,
  .header {
    color: vars.$gray-light-color;
  }
  .cell {
    border-right: 1px solid vars.$gray-light-color;
    border-bottom: 1px solid vars.$gray-light-color;
  }
}

.disabledActive {
  .cellActive {
    background-color: vars.$scheduler_non_editable_color;
  }
}

.compact {
  .fullDisplay {
    display: none !important;
  }
  .compactDisplay {
    display: inline !important;
  }
  .compactSmall {
    font-size: 0.5rem;
  }
}

@include media-breakpoint-down(lg) {
  .fullDisplay {
    display: none !important;
  }
  .compactDisplay {
    display: inline !important;
  }
  .compactSmall {
    font-size: 0.5rem;
  }
}
