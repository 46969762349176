$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-send-opt-in: unquote('"\\e966"');
$icon-file-upload: unquote('"\\e965"');
$icon-ai: unquote('"\\e964"');
$icon-send-text: unquote('"\\e962"');
$icon-send-poll: unquote('"\\e963"');
$icon-paperclip: unquote('"\\e961"');
$icon-template-action: unquote('"\\e960"');
$icon-clickers: unquote('"\\e95f"');
$icon-template-copy: unquote('"\\e95e"');
$icon-c-file-check: unquote('"\\e959"');
$icon-c-number-lookup: unquote('"\\e95a"');
$icon-c-clone: unquote('"\\e95b"');
$icon-c-phone-book: unquote('"\\e95c"');
$icon-c-text: unquote('"\\e95d"');
$icon-clone-new: unquote('"\\e900"');
$icon-clone-failed: unquote('"\\e901"');
$icon-campaigns: unquote('"\\e902"');
$icon-dashboard: unquote('"\\e903"');
$icon-import: unquote('"\\e904"');
$icon-ip-proxies: unquote('"\\e905"');
$icon-knowledge-base: unquote('"\\e906"');
$icon-link-book: unquote('"\\e907"');
$icon-mobile: unquote('"\\e908"');
$icon-phone-book: unquote('"\\e909"');
$icon-prices: unquote('"\\e90a"');
$icon-routes: unquote('"\\e90b"');
$icon-short-domain: unquote('"\\e90c"');
$icon-shortlink-proxy: unquote('"\\e90d"');
$icon-sms-test: unquote('"\\e90e"');
$icon-statistics: unquote('"\\e90f"');
$icon-stoplist: unquote('"\\e910"');
$icon-transactions: unquote('"\\e911"');
$icon-users: unquote('"\\e912"');
$icon-way-messaging: unquote('"\\e913"');
$icon-default: unquote('"\\e914"');
$icon-infobip: unquote('"\\e915"');
$icon-infobip-sign: unquote('"\\e916"');
$icon-twilio: unquote('"\\e917"');
$icon-twilio-sign: unquote('"\\e918"');
$icon-account: unquote('"\\e919"');
$icon-actions: unquote('"\\e91a"');
$icon-add: unquote('"\\e91b"');
$icon-alert: unquote('"\\e91c"');
$icon-archive: unquote('"\\e91d"');
$icon-archived: unquote('"\\e91e"');
$icon-back: unquote('"\\e91f"');
$icon-basket: unquote('"\\e920"');
$icon-blog: unquote('"\\e921"');
$icon-chevron: unquote('"\\e922"');
$icon-clear_input: unquote('"\\e923"');
$icon-close: unquote('"\\e924"');
$icon-copy: unquote('"\\e925"');
$icon-custom: unquote('"\\e926"');
$icon-delete: unquote('"\\e927"');
$icon-download: unquote('"\\e928"');
$icon-edit: unquote('"\\e929"');
$icon-exit: unquote('"\\e92a"');
$icon-export: unquote('"\\e92b"');
$icon-faq: unquote('"\\e92c"');
$icon-file: unquote('"\\e92d"');
$icon-filter: unquote('"\\e92e"');
$icon-finished: unquote('"\\e92f"');
$icon-guide: unquote('"\\e930"');
$icon-home: unquote('"\\e931"');
$icon-integration: unquote('"\\e932"');
$icon-log: unquote('"\\e933"');
$icon-menu: unquote('"\\e934"');
$icon-notification: unquote('"\\e935"');
$icon-not-show: unquote('"\\e936"');
$icon-number_check: unquote('"\\e937"');
$icon-pause: unquote('"\\e938"');
$icon-play: unquote('"\\e939"');
$icon-reload: unquote('"\\e93a"');
$icon-report: unquote('"\\e93b"');
$icon-save: unquote('"\\e93c"');
$icon-search: unquote('"\\e93d"');
$icon-select: unquote('"\\e93e"');
$icon-settings: unquote('"\\e93f"');
$icon-share: unquote('"\\e940"');
$icon-show: unquote('"\\e941"');
$icon-stop: unquote('"\\e942"');
$icon-stop-list: unquote('"\\e943"');
$icon-upm: unquote('"\\e944"');
$icon-upm-sign: unquote('"\\e945"');
$icon-custom-route: unquote('"\\e946"');
$icon-twillio-sign: unquote('"\\e947"');
$icon-colored-upm-sign-1: unquote('"\\e948"');
$icon-colored-upm-sign-2: unquote('"\\e949"');
$icon-colored-upm-sign-3: unquote('"\\e94a"');
$icon-incoming-message: unquote('"\\e94b"');
$icon-image: unquote('"\\e94c"');
$icon-folder: unquote('"\\e94d"');
$icon-waiting: unquote('"\\e94e"');
$icon-checkmark-stroke: unquote('"\\e94f"');
$icon-need-attention: unquote('"\\e950"');
$icon-sent: unquote('"\\e951"');
$icon-alert-filled-circle: unquote('"\\e952"');
$icon-warning: unquote('"\\e953"');
$icon-taken-over: unquote('"\\e954"');
$icon-abandoned: unquote('"\\e955"');
$icon-in-progress: unquote('"\\e956"');
$icon-thesaurus: unquote('"\\e957"');
$icon-conversation-templates: unquote('"\\e958"');

