@use "src/vars";

.logoColor1 {
	fill: vars.$primary-color;
	composes: theme-logo__color1 from global;
}

.logoColor2 {
	fill: lighten(vars.$primary-color, 10%);
	composes: theme-logo__color2 from global;
}

.rotate180 {
	transform: rotate(180deg);
}
