@import "src/vars.scss";

.labelStyle {
	background-color: #FEF8F6;
	border: 1px dashed lighten($primary-color, 21%);
	box-shadow: inset 0 0 0 2px lighten($layout-light, 21%);
	min-height: 200px;
	transition: all 200ms ease;
	cursor: pointer;
	border-radius: 12px;
}

.labelStyle:hover {
	border: 1px solid $primary-color;
	box-shadow: inset 0 0 0 2px $primary-color;
	background-color: #FEF8F6;
}

.fileUploader {
	//background-color: lighten(#FEF8F6, 21%);
	min-height: 200px;
	transition: all 200ms ease;
}

.fileUploader.active {
	cursor: pointer;
}

.fileUploader h4 {
	overflow-wrap: break-word;
	overflow: hidden;
	max-width: 100%;
}

.exists {
	border: 1px solid $success-color;
	box-shadow: inset 0 0 0 2px $success-color;
}

.uploadButton {
	min-height: 0 !important;
}