.avatar {
	width: 180px;
	height: 180px;
	cursor: pointer;
	&:hover {
		background-color: #120047;
		img {
			opacity: 0.3;
		}
		span {
			opacity: 0.85;
		}
	}
}