@use "src/vars";

.header {
  height: 70px;
  background-color: vars.$header-bg-color;
  color: #fff;
  padding: 15px 2%;
  border-bottom: 1px solid #323252;
  position: fixed;
  width: 100%;
  z-index: 10;
  min-width: 850px;
}

.balance {
  color: vars.$header-text-color;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.pp {
  border-radius: 8px;
  padding: 5px 7px;
  text-align: center;
  display: flex;
  width: 46px;
  height: 46px;
  align-items: center;
}
