 @import "../../vars";

.terms {
	h1 {
		font-size: 2rem;
	}
	h2 {
		font-size: 1.6rem;
	}
	h3 {
		font-size: 1.4rem;
	}
	h4 {
		font-size: 1.2rem;
	}
	h5 {
		font-size: 1.1rem;
	}
}

.notifyText {
	font-family: "Roboto", sans-serif;
	font-weight: bold;
	font-size: 1.1rem;
}

.tc_text {
	font-family: "Roboto", sans-serif;
	font-size: 0.9rem;
}

.listBlock {
	border-radius: 10px;
	min-height: 80px;
	height: 100%;
	background-color: white;
	display: flex;
	align-items: center;
	background: #F6F8FA;
}
.tclistBlock {
	padding: 0.3rem 0.11rem;
}

.iconButton {
	min-width: 1.2em;
}