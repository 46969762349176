.textSourceName {
	color: #5E7182;
	font-size: 14px;
	max-height: 30%;
	font-weight: normal;
}

.truncate {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}