@use "src/vars";

@mixin menu-item {
  color: vars.$header-text-color;
  text-decoration: none;
  display: inline-block;
  padding-bottom: 20px;
  padding-top: 9px;

  &:hover, &:active {
    color: vars.$header-text-color;
    text-decoration: none;
  }
}

.navItemLink {
  @include menu-item;

  &:hover {
    border-bottom: 2px solid vars.$header-text-color;
    padding-bottom: 18px;
  }
}

.navItemActive {
  @include menu-item;
  color: vars.$primary-color;
  border-bottom: 2px solid vars.$primary-color;
  pointer-events: none;
  cursor: auto;
  padding-bottom: 18px;
}

.navItemContent {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
