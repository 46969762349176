@use "src/vars";

.replyKeywords {
	width: 200px;
}

.replyAction {
	width: 200px;
}

.replyPreview {
	font-family: monospace;
	background-color: #f1f1f1;
	font-size: 0.9rem;
}

.campaignCR {
	color: #017883;
	font-weight: bold;
}

.campaignName {
	max-height: 3rem;
	line-height: 1.5rem;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	max-width: 100%;
	word-wrap: break-word;
	text-overflow: ellipsis;
	white-space: normal;
}

.routeName {
	max-height: 3rem;
	line-height: 1.5rem;
	overflow: hidden;
	word-wrap: break-word;
	text-overflow: ellipsis;
	white-space: normal;
}

.campaignDateTime {
	font-size: smaller;
}

@media (min-width: 768px) {
	.campaignDetails {
		max-width: 340px;
		padding-right: 15px !important;
	}
}

.attentionSign {
	width: 8px;
	height: 8px;
	background: #E3001B;
	flex: none;
	order: 0;
	flex-grow: 0;
}
