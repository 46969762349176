@use "src/vars";

.stepper {
	position: relative;
	border-left: 2px solid vars.$title-color;
	padding-left: 90px;
	padding-top: 10px;
	min-height: 150px;
	margin-bottom: 40px;
}

.stepNumberWrapper {
	position: absolute;
	left: -28px;
	top: -7px;
	background-color: vars.$page-layout-bg-color;
	height: 82px;
}

.stepNumber {
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	padding: 16px;
	border-radius: 100px;
	border-bottom: 2px solid vars.$title-color;
}
