$primary-color: #EF3F08;
$primary-color-dark: #BE1E1E;
$secondary-color: #000020;
$secondary-reverse-color: #fff;
$head-color: #120047;
$layout-light: #F6F8FA;
$layout-dark: #7C788A;
$link-color: #0317BA;

$header-bg-color: $secondary-color;
$body-bg-color: $layout-light;
$page-layout-bg-color: $secondary-reverse-color;
$text-color: $secondary-color;
$header-text-color: $secondary-reverse-color;
$title-color: $head-color;
$header-text-secondary: $layout-dark;

$success-color: #01A76F;
$warning-color: #FFBA34;
$error-color: #FF0D2A;
$info-color: #094AF0;
$stat-color: #7200B1;


$gray-mid-color: $header-text-secondary;
$dark-color: #0099A7;
$gray-light-color: #DCDCDC;
$scheduler_non_editable_color: #89b7bb;
$main-border-color: #ECEEF0;


/*

$form-control-bg-color: white;

$gray-mid-color: #C2C2C2;

$light-color: #DF2355;


$header-size-lg: 50px;
$header-size-sm: 40px;
$side-bar-lg: 250px;
$side-bar-sm: 60px;
$side-gradient-start: #00818d;
$side-gradient-mid: #0091A0;
$side-gradient-end: #00cbca;
$bg-select:	#92d1d9;
$color-active: #00a5ac;
$down-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);
$main-border-color: #dddddd;
$error-border-color: #fda3bb;
$placeholder-color: #dddddd;
$light-bg-color: #fff;
$text-color: #405364;
$header-bg-color: #d8f5f4;
$header-filter-bg-color: #d2eeed;

$body-bg: #EBEFF2;

$light-color: #DF2355;
$dark-color: #0099A7;
$dark-color-level2: #a2805c;

$gray-extra-extra-light-color: #F6F7F8;
$gray-extra-light-color: #E0E0E0;
$gray-light-color: #DCDCDC;
$gray-before-mid-color: #D0D0D0;
$gray-mid-color: #C2C2C2;
$gray-dark-color: #898989;

$error-color: #df2355;
$warning-color: #f29a08;
$success-color: #06a177;
$notice-color: #3C67B0;

$background-wrong-color: #fceef2;

$scheduler_non_editable_color: #89b7bb;
*/
