@use 'vars';
@use '~bootstrap/scss/bootstrap';
@use 'media/icomoon/style';

//@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap');
//@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import 'material-icons/iconfont/material-icons.css';
@import '@fontsource/rubik/400.css';
@import '@fontsource/rubik/500.css';
@import '@fontsource/rubik/600.css';

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 360px;
}

a {
  color: vars.$link-color;
  text-decoration: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */

  &:hover {
    color: vars.$link-color;
    text-decoration: underline;
  }
}

#root {
  min-height: 100%;
}

.loader-background-color[class] {
  background-color: vars.$body-bg-color;
}

/* Bootstrap reboot + MUI fixes */
legend {
  width: auto;
}
.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: white;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

strong {
  font-weight: 500;
}
.bg-primary[class] {
  background-color: rgba(0, 153, 167, var(--bs-bg-opacity)) !important;
}
/* end of Bootstrap reboot + MUI fixes */

.pointer {
  cursor: pointer;
}
